import React, { useEffect } from 'react'

import CampaignList from '../../components/campaign-list/campaign-list'
import SearchBar from '../../components/search-bar/search-bar'

import { useSetRecoilState } from 'recoil'
import { campaignsListState, router } from '../../AppState'
import LayoutContainer from '../../components/layout-container'
import Loading from '../../components/loading'
import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { onGetUserCampaignList } from '@src/services/campaign.service'

export function UserCampaignsPage() {
    const setCampaigns = useSetRecoilState(campaignsListState)

    const campaignsQuery = useQuery({
        queryKey: ['user-campaigns'],
        queryFn: () => onGetUserCampaignList(),
        retry: false,
    })

    useEffect(() => {
        if (campaignsQuery.isError) {
            if ((campaignsQuery.error as any)?.status === 401) {
                toast.error('Sua sessão expirou, por favor faça login novamente.')
                return router.history.push('/logout')
            }
        }
    }, [campaignsQuery.isError])

    useEffect(() => {
        if (campaignsQuery.isSuccess && campaignsQuery.data) {
            setCampaigns(campaignsQuery.data)
        }
    }, [campaignsQuery.data])

    return (
        <LayoutContainer>
            <div className='flex flex-col w-full mx-auto'>
                <header className='flex justify-between'>
                    <h1 className='text-lg sm:text-2xl font-bold grow'>Campanhas</h1>
                </header>
                <SearchBar />
            </div>
            <div className='flex-grow overflow-y-auto'>
                <Loading className='flex-1' isLoading={campaignsQuery.isLoading || campaignsQuery.isRefetching}>
                    <CampaignList />
                </Loading>
            </div>
        </LayoutContainer>
    )
}
