import React, { MutableRefObject, useMemo, useRef } from 'react'
import ButtonOption from './button-option/button-option'
import ConfirmationDialog from '../dialogs/confirmation-dialog'
import EditCampaignDialog from '../dialogs/edit-campaign-dialog'

import {
    BsFillCloudDownloadFill,
    BsPeopleFill,
    BsListUl,
    BsPencilSquare,
    BsEyeFill,
    BsTrashFill,
    BsArrowRepeat,
} from 'react-icons/bs'

import { campaignsListState } from '../../AppState'
import { useSetRecoilState } from 'recoil'

import toast from 'react-hot-toast'
import { campaignOptions } from '../../services/campaign.service'
import { Campaign } from '../../types'
import { getClientCampaigns } from '@src/services/clients.service'

type Props = {
    campaign: Campaign
}

const CampaignOptions = ({ campaign }: Props) => {
    const { client, name, key } = campaign
    const campaignUrl = useMemo(() => `${campaign.url}/${campaign.key}`,
        [campaign.url, campaign.key])

    const setCampaigns = useSetRecoilState(campaignsListState)

    const optionsDialogRef = useRef<HTMLDialogElement | null>(null)
    const confirmDialogRef = useRef<HTMLDialogElement | null>(null)
    const editDialogRef = useRef<HTMLDialogElement | null>(null)

    const {
        onUpdateCampaign,
        onEditCampaign,
        onDownloadReport,
        goToCampaign,
        goToActionsHistory,
        goToUserList,
    } = campaignOptions

    const setCampaignListState = async () => {
        const updatedList = await getClientCampaigns(client)
        setCampaigns(updatedList)
    }

    const handleDownloadReport = () => {
        const myPromise = onDownloadReport(client, key)
        toast.promise(myPromise, {
            loading: `Preparando arquivo para a campanha: ${name}...`,
            success: 'Arquivo preparado, o download será iniciado!',
            error: 'Erro ao preparar arquivo, tente novamente.',
        })
    }

    const handleDeleteCampaign = () => {
        const myPromise = onEditCampaign(key, { isHidden: true })
        toast.promise(myPromise, {
            loading: 'Arquivando campanha...',
            success: 'Campanha arquivada com sucesso!',
            error: 'Erro ao arquivar campanha.',
        }).finally(setCampaignListState)
    }

    const handleUpdateCampaign = () => {
        const myPromise = onUpdateCampaign(key)
        toast.promise(myPromise, {
            loading: 'Atualizando campanha...',
            success: 'Campanha atualizada com sucesso!',
            error: 'Erro ao atualizar campanha.',
        }).finally(() => window.location.reload())
    }

    const handleOpenDialog = ({ current: dialog }: MutableRefObject<HTMLDialogElement | null>) => {
        // usando esse método para abrir os modais
        // da daisyui preservamos a animação 
        if (dialog) dialog.showModal()
    }

    return (
        <div>
            <button tabIndex={0} className='btn btn-neutral btn-sm rounded-btn' onClick={handleOpenDialog.bind(null, optionsDialogRef)}>
                Ver opções
            </button>
            <dialog className='modal' ref={optionsDialogRef}>
                <div className='modal-box bg-base-300 rounded-md w-fit h-fit'>
                    <form method='dialog' className='flex flex-col gap-y-4'>
                        <ButtonOption
                            icon={<BsArrowRepeat />}
                            label='Atualizar Assets'
                            onClick={handleUpdateCampaign}
                        />
                        <ButtonOption
                            icon={<BsPencilSquare />}
                            label='Editar campanha'
                            onClick={handleOpenDialog.bind(null, editDialogRef)}
                        />
                        <ButtonOption
                            icon={<BsFillCloudDownloadFill />}
                            label='Baixar relatório'
                            onClick={handleDownloadReport}
                        />
                        <ButtonOption
                            icon={<BsEyeFill />}
                            label='Detalhes da campanha'
                            onClick={goToCampaign.bind(null, campaignUrl)}
                        />
                        <ButtonOption
                            icon={<BsListUl />}
                            label='Histórico de ações'
                            onClick={goToActionsHistory.bind(null, key)}
                        />
                        <ButtonOption
                            icon={<BsPeopleFill />}
                            label='Lista de usuários'
                            onClick={goToUserList.bind(null, client, key)}
                        />
                        <ButtonOption
                            icon={<BsTrashFill />}
                            label='Arquivar campanha'
                            onClick={handleOpenDialog.bind(null, confirmDialogRef)}
                        />
                    </form>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>

            <ConfirmationDialog
                title={`Tem certeza que deseja excluir a campanha: ${name}?`}
                describe='Essa ação não pode ser desfeita.'
                onConfirm={handleDeleteCampaign}
                ref={confirmDialogRef}
            />

            <EditCampaignDialog
                campaign={campaign}
                ref={editDialogRef}
            />
        </div>
    )
}

export default CampaignOptions
