import React, { useEffect, useMemo } from 'react'

import CampaignList from '../../components/campaign-list/campaign-list'
import CreateCampaignDialog from '../../components/dialogs/create-campaign-dialog'
import SearchBar from '../../components/search-bar/search-bar'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { campaignsListState, currentUserAtom, router } from '../../AppState'
import LayoutContainer from '../../components/layout-container'
import Loading from '../../components/loading'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getClientCampaigns } from '@src/services/clients.service'
import toast from 'react-hot-toast'
import { Breadcrumb } from '@src/components/breadcrumb'

export function AdminCampaignsPage() {
    const setCampaigns = useSetRecoilState(campaignsListState)
    const currentUser = useRecoilValue(currentUserAtom)
    const params = useParams()

    const client = useMemo(() => params.client, [params.client])

    const campaignsQuery = useQuery({
        queryKey: ['campaigns', client],
        queryFn: () => getClientCampaigns(client as string),
        retry: false,
    })

    const onCreateSuccess = async () => {
        campaignsQuery.refetch()
    }

    useEffect(() => {
        if (campaignsQuery.isError) {
            if ((campaignsQuery.error as any)?.status === 401) {
                toast.error('Sua sessão expirou, por favor faça login novamente.')
                return router.history.push('/logout')
            }
        }
    }, [campaignsQuery.isError])

    useEffect(() => {
        if (campaignsQuery.isSuccess && campaignsQuery.data) {
            setCampaigns(campaignsQuery.data)
        }
    }, [campaignsQuery.data])

    return (
        <LayoutContainer>
            <div className='flex flex-col w-full mx-auto'>
                <header className='flex justify-between'>
                    <h1 className='text-lg sm:text-2xl font-bold grow'>Campanhas [{client}]</h1>
                    <CreateCampaignDialog onCreateSuccess={onCreateSuccess} />
                </header>
                {currentUser?.company === 'stormx' && (
                    <div className='w-full my-4'>
                        <Breadcrumb pages={[{ name: 'Clientes', href: '/admin/clients', current: false }]} />
                    </div>
                )}
                <SearchBar />
            </div>
            <div className='flex-grow overflow-y-auto'>
                <Loading className='flex-1' isLoading={campaignsQuery.isLoading || campaignsQuery.isRefetching}>
                    <CampaignList />
                </Loading>
            </div>
        </LayoutContainer>
    )
}
