import { Client } from "@src/types/client";
import { HttpService } from "./infra/http.service";
import { Campaign } from "@src/types";

type GetClientResponse = {
  ok: boolean;
  clients: Client[];
};

export const getClients = async () => {
  try {
    const request = await new HttpService().request<GetClientResponse>({
      method: 'GET',
      url: '/api/admin/clients',
    })
    const result = request.data

    if (result.ok)
      return result.clients

    throw new Error('Error when searching for clients.')
  } catch (error: any) {
    if (error instanceof Error) {
      console.error('Error: ', error.message)
    } else {
      console.error(error)
    }

    return Promise.reject({
      message: error?.response?.message || 'An unexpected error occurred.',
      status: error?.response?.status || 500,
    })
  }
}

type GetClientCampaignsResponse = {
  ok: boolean;
  campaigns: Campaign[];
};

export const getClientCampaigns = async (clientKey: string): Promise<Campaign[]> => {
  if (!clientKey)
    return Promise.reject('Client key is required.')

  try {
    const request = await new HttpService().request<GetClientCampaignsResponse>({
      method: 'GET',
      url: `/api/common/client/${clientKey}/campaigns`,
    })
    const result = request.data

    if (result.ok)
      return result.campaigns

    throw new Error('Error when searching for campaigns.')
  } catch (error: any) {
    if (error instanceof Error) {
      console.error('Error: ', error.message)
    } else {
      console.error(error)
    }

    return Promise.reject({
      message: error?.response?.message || 'An unexpected error occurred.',
      status: error?.response?.status || 500,
    })
  }
}

type CreateClientResponse = {
  ok: boolean;
  client: Client;
};

export const createClient = async (client: string): Promise<Client> => {
  try {
    const request = await new HttpService().request<CreateClientResponse>({
      method: 'POST',
      url: '/api/admin/clients',
      data: { client }
    })
    const result = request.data

    if (result.ok)
      return result.client

    throw new Error('Error when creating client.')
  } catch (error: any) {
    if (error instanceof Error) {
      console.error('Error: ', error.message)
    } else {
      console.error(error)
    }

    return Promise.reject({
      message: error?.response?.message || 'An unexpected error occurred.',
      status: error?.response?.status || 500,
    })
  }
}