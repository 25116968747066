import { atom, selector } from 'recoil'
import { makeRouter } from '@lagunovsky/recoil-react-router'
import { Creative, Comment, User, Campaign, CampaignListOrder, CampaignGeneralStatus } from './types'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()
export const router = makeRouter()
// router.history.push('/hello')

export const currentUserAtom = atom<User | null>({
    default: null,
    key: 'current.user',
    effects_UNSTABLE: [persistAtom],
})

export const zoomState = atom({ default: 1.0, key: 'zoom' })

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max)
}

function randomAnimal(): string {
    const emails = [
        'alex@cats.stormx.com.br',
        'cake@cats.stormx.com.br',
        'regina@cats.stormx.com.br',
        'capitu@cats.stormx.com.br',
        'crystal@cats.stormx.com.br',
        'mel@dogs.stormx.com.br',
    ]

    return emails[getRandomInt(emails.length)]
}

export const currentAnimal = atom<string>({ default: randomAnimal(), key: 'current.animal' })

export const currentCreative = atom<Creative | null>({
    default: null,
    key: 'creative.current',
})

export const CreativesList = atom<Creative[]>({
    default: [],
    key: 'creative.list',
})

export const PlayerStatus = atom({
    default: {
        isPlaying: false,
        time: 0,
        totalTime: 10000,
    },
    key: 'player.status',
})

export const CommentsList = atom<Comment[]>({
    default: [],
    key: 'comments.list',
})

export const fullscreenState = atom({
    default: false,
    key: 'fullscreen',
})

export const CampaignDetails = atom<Campaign>({
    default: {} as Campaign,
    key: 'campaign.details',
})

export const isGridLayout = atom<boolean>({
    default: false,
    key: 'gallery.layout',
    effects_UNSTABLE: [persistAtom],
})

export const isDevAmbientState = atom({
    default: false,
    key: 'project.node_env'
})

export const listOpenedState = atom<boolean>({
    default: true,
    key: 'gallery.list.opened',
    effects_UNSTABLE: [persistAtom],
})

export const selectedTagsState = atom<Set<string>>({
    default: new Set(),
    key: 'filter.selected.tags',
})

export const selectedStatusState = atom<Set<string>>({
    default: new Set(['approved', 'reproved', 'revised', 'new']),
    key: 'filter.selected.status',
})

export const allTagsState = atom<string[] | null>({
    default: null,
    key: 'filter.tags',
})

export const filteredListSelector = selector<Creative[]>({
    key: 'filter.creative.list',
    get: ({ get }) => {
        const selectedStatus = get(selectedStatusState)
        const selectedTags = get(selectedTagsState)
        const creativeList = get(CreativesList)
        const allTags = get(allTagsState)

        const tags = allTags || ['']
        return [...creativeList]
            .sort((a, b) => {
                if (a.updatedAt > b.updatedAt) {
                    return -1
                }
                if (a.updatedAt < b.updatedAt) {
                    return 1
                }
                return a.name.localeCompare(b.name)
            })
            .filter(
                (creative) =>
                    selectedTags.size === 0 ||
                    Array.from(selectedTags)
                        .filter((tag) => tags.indexOf(tag) >= 0)
                        .every((tag) => creative.tags.indexOf(tag) >= 0)
            )
            .filter((creative) => selectedStatus.has(creative.status))
    },
})

export const searchTextState = atom<string>({
    key: 'campaign.filter.searchText',
    default: '',
})

export const campaignsListState = atom<Campaign[]>({
    key: 'campaign.list',
    default: [],
})

export const clientFilterState = atom<string | null>({
    key: 'campaign.filter.client',
    default: null,
})

export const campaignListOrderState = atom<CampaignListOrder>({
    key: 'campaign.filter.orderRule',
    default: {
        by: 'createdAt',
        ascending: true,
    },
    effects_UNSTABLE: [persistAtom]
})

export const filteredCampaignListSelector = selector<Campaign[]>({
    key: 'campaign.filtered.list',
    get: ({ get }) => {
        const search = get(searchTextState)
        const campaigns = get(campaignsListState)
        const { by, ascending } = get(campaignListOrderState)

        if (!campaigns || campaigns.length === 0) return []
        const orderByDate = by === 'createdAt' || by === 'updatedAt'
        const filteredCampaign = campaigns.filter((camp) =>
            camp.name.toLowerCase().includes(search.toLowerCase())
        )

        if (orderByDate) {
            const orderedCampaignList = filteredCampaign.sort((a, b) => {
                const dateA = new Date(a[by])
                const dateB = new Date(b[by])
                if (dateA < dateB) return -1
                if (dateA > dateB) return 1
                return 0
            })
            return ascending ? orderedCampaignList.reverse() : orderedCampaignList
        } else {
            const orderedCampaignList = filteredCampaign.sort((a, b) =>
                a[by].localeCompare(b[by], undefined, { sensitivity: 'base', ignorePunctuation: true })
            )
            return ascending ? orderedCampaignList : orderedCampaignList.reverse()
        }
    }
})

export const campaignDetailsSelector = selector<CampaignGeneralStatus>({
    key: 'campaign.general.status',
    get: ({ get }) => {
        const creativeList = get(CreativesList)
        const total = creativeList.length
        const displaying = get(filteredListSelector).length

        const approved = creativeList.filter(creative => creative.status == 'approved').length
        const reproved = creativeList.filter(creative => creative.status == 'reproved').length
        const revised = creativeList.filter(creative => creative.status == 'revised').length
        const noStatus = creativeList.filter(creative => creative.status == 'new').length

        return {
            new: noStatus,
            approved,
            reproved,
            revised,
            total,
            displaying
        }
    }
})

export const currentFileIsHTML = atom<boolean>({
    key: 'current.file.isHTML',
    default: true
})