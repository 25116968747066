import React from 'react'

import TableHeader from './table-header'
import TableBody from './table-body/table-body'
import classNames from 'classnames'
import { filteredCampaignListSelector } from '@src/AppState'
import { useRecoilValue } from 'recoil'

const CampaignList = () => {
    const campaigns = useRecoilValue(filteredCampaignListSelector)

    return (
        <div className={classNames('overflow-x-auto w-full mx-auto h-full', {})}>
            {campaigns.length === 0 ? (
                <div className='w-full text-center mt-16'>
                    <span className='text-xl'>Não há campanhas</span>
                </div>
            ) : (
                <table className='table table-pin-rows table-pin-cols w-full rounded-md bg-base-400'>
                    <TableHeader />
                    <TableBody />
                </table>
            )}
        </div>
    )
}

export default CampaignList
