import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getClients } from '@src/services/clients.service';
import LayoutContainer from '@src/components/layout-container';
import Loading from '@src/components/loading';
import { twMerge } from 'tailwind-merge';
import { router } from '@src/AppState';
import { BiSearchAlt as SearchIcon } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import CreateClientDialog from '@src/components/dialogs/create-client-dialog';
import { Client } from '@src/types/client';

export const ClientsList = () => {
  const [search, setSearch] = useState<string | null>(null)
  const [clients, setClients] = useState<Client[]>([])

  const clientsQuery = useQuery({
    queryKey: ['clients'],
    queryFn: getClients,
  })

  useEffect(() => {
    if (clientsQuery.isError) {
      if ((clientsQuery.error as any)?.status === 401) {
        toast.error('Sua sessão expirou, por favor faça login novamente.')
        return router.history.push('/logout')
      }
    }
  }, [clientsQuery.isError])

  useEffect(() => {
    if (clientsQuery.isSuccess && clientsQuery.data) {
      const filteredClients = clientsQuery.data.filter((client: Client) => {
        if (search) {
          return client.name.toLowerCase().includes(search.toLowerCase())
        }
        return true
      })

      setClients(filteredClients)
    }
  }, [clientsQuery.data, search])

  const onCreateSuccess = async () => {
    clientsQuery.refetch()
  }

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)

    return () => {
      setSearch(null)
    }
  }, [])

  if (clientsQuery.isError)
    return <div>Erro inesperado!</div>

  return (
    <LayoutContainer>
      <div className='flex flex-col w-full mx-auto'>
        <header className='flex justify-between'>
          <h1 className='text-lg sm:text-2xl font-bold grow'>Clientes</h1>
          <CreateClientDialog onCreateSuccess={onCreateSuccess} />
        </header>

        <div className='flex flex-row gap-x-4 flex-nowrap form-control w-full my-4'>
          <div className='flex items-center w-full bg-base-100 rounded-lg pr-4 focus-within:ring focus-within:ring-blue-500 focus-within:rounded'>
            <input
              type='text'
              placeholder={`Busque clientes pelo nome...`}
              className={'input input-bordered w-full bg-transparent border-0 focus:border-0 focus:outline-none'}
              onChange={handleSearch}
            />
            <SearchIcon size='1.5rem' />
          </div>
        </div>
      </div>
      <div className='flex-grow overflow-y-auto pr-4'>
        <Loading className='flex-1' isLoading={clientsQuery.isLoading || clientsQuery.isRefetching}>
          {clientsQuery.isSuccess && (
            clients.map((client, index) => (
              <Link
                key={client.key}
                to={`/view/${client.key}`}
                className={twMerge(
                  'flex justify-between p-4 border-gray-400 cursor-pointer',
                  index > 0 && 'mt-2 border-t-2'
                )}
              >
                <span>{client.name}</span>
                <span>-&gt;</span>
              </Link>
            ))
          )}
        </Loading>
      </div>
    </LayoutContainer>
  );
}
