import React, { useTransition, ChangeEvent, useEffect } from 'react'
import ButtonChangeOrder from '../button-change-order'
import { BiSearchAlt as SearchIcon } from 'react-icons/bi'
import { useSetRecoilState } from 'recoil'
import { searchTextState } from '../../AppState'

const SearchBar = () => {
    const setSearch = useSetRecoilState(searchTextState)
    const [isPending, startTransition] = useTransition()

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        startTransition(() => {
            setSearch(event.target.value)
        })
    }

    useEffect(() => {
        setSearch('')

        return () => {
            setSearch('')
        }
    }, [])

    return (
        <div className='flex flex-row gap-x-4 flex-nowrap form-control w-full my-4'>
            <div className='flex items-center w-full bg-base-100 rounded-lg pr-4 focus-within:ring focus-within:ring-blue-500 focus-within:rounded'>
                <input
                    type='text'
                    placeholder={`Busque campanhas pelo nome...`}
                    className={'input input-bordered w-full bg-transparent border-0 focus:border-0 focus:outline-none'}
                    onChange={handleSearch}
                />
                <SearchIcon size='1.5rem' />
            </div>


            <ButtonChangeOrder />
        </div>
    )
}

export default SearchBar
