import React, { useState } from 'react'
import TextField from '../../text-field/text-field'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { createClient } from '@src/services/clients.service'

interface CreateCampaignDialogProps {
    onCreateSuccess?: () => void
}

const CreateClientDialog = ({
    onCreateSuccess
}: CreateCampaignDialogProps) => {
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [clientForm, setClientForm] = useState<{ client: string }>({} as any)

    const createClientMutation = useMutation({
        mutationFn: createClient,
        onSuccess: () => {
            onCreateSuccess && onCreateSuccess()
            setShowDialog(false)
            setClientForm({} as any)
            toast.success('Cliente criado com sucesso!')
        },
        onError: () => {
            toast.error('Erro ao criar cliente.')
        }
    })

    const handleToogleDialog = () => {
        setShowDialog(!showDialog)
    }

    const handleCreateClient = async () => {
        if (!clientForm.client)
            return;

        createClientMutation.mutateAsync(clientForm.client)
        toast.loading('Criando cliente...')
    }

    return (
        <>
            <button className={classNames('btn btn-neutral btn-xs sm:btn-sm')} onClick={handleToogleDialog}>
                Adicionar
            </button>

            {showDialog && (
                <div className='modal modal-open modal-bottom sm:modal-middle bg-slate-800/90 '>
                    <div className='modal-box'>
                        <h3 className='font-bold text-lg'>Novo cliente:</h3>
                        <button
                            className={classNames('btn btn-neutral btn-sm btn-circle absolute right-4 top-4', {
                                'loading cursor-wait:': createClientMutation.isPending,
                            })}
                            onClick={handleToogleDialog}
                        >
                            {!createClientMutation.isPending && 'x'}
                        </button>

                        <TextField
                            label='Nome'
                            name='client'
                            placeholder='Cliente 1'
                            value={clientForm.client}
                            readOnly={createClientMutation.isPending}
                            onStateChange={setClientForm}
                        />

                        <div className='modal-action'>
                            <button
                                onClick={handleToogleDialog}
                                disabled={createClientMutation.isPending}
                                className={classNames('btn btn-neutral', {
                                    'loading cursor-wait': createClientMutation.isPending,
                                })}
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleCreateClient}
                                disabled={createClientMutation.isPending}
                                className={classNames('btn btn-primary', {
                                    'loading cursor-wait': createClientMutation.isPending,
                                })}
                            >
                                Adicionar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CreateClientDialog
