import React from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { RecoilReactRouter } from '@lagunovsky/recoil-react-router'
import { Route, Routes } from 'react-router'
import { Toaster } from 'react-hot-toast'
import { Tooltip } from 'react-tooltip'
import { useRecoilValue } from 'recoil'

import { currentUserAtom, router } from './AppState'

import { AdminCampaignsPage, UserCampaignsPage } from './admin/campaigns'
import Users from './admin/campaigns/users'
import Gallery from './Views/gallery'
import Empty from './empty'
import CheckAdminAuth from './auth/admin'
import NeedLogin from './auth/login'
import AdminUsers from './admin/users'

import Timeline from './timeline'
import Navbar from './components/navbar'
import Warning from './components/warning'

import 'react-tooltip/dist/react-tooltip.css'
import classNames from 'classnames'
import ClientLogin from './auth/client'
import { Logout } from './auth/logout'

import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ClientsList } from './admin/clients'
import { Navigate } from 'react-router-dom'

dayjs.extend(customParseFormat)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	}
})

export function App() {
	const currentUser = useRecoilValue(currentUserAtom)
	const isDev = process.env.NODE_ENV === 'development'
	const isUat = window.location.hostname.includes('uat.aprove.orom.com.br')

	return (
		<QueryClientProvider client={queryClient}>
			<main>
				{isUat && (
					<div className='w-full h-6 bg-green-600 flex justify-center items-center'>
						<p className='text-center text-white font-medium'>
							Este é um ambiente beta para teste de novas funções.
						</p>
					</div>
				)}

				<Navbar />
				<div className={classNames('w-full overflow-hidden mx-auto p-4', {
					'h-development': isDev,
					'h-production': !isDev && !isUat,
					'h-uat': isUat
				})}>
					<RecoilReactRouter router={router}>
						<Routes>
							<Route path={'/logout'} element={<Logout />} />
							<Route path={'/admin'} element={<CheckAdminAuth />} />
							<Route path={'/admin/redirect'} element={<CheckAdminAuth />} />

							{currentUser === null || typeof currentUser === 'undefined' ? (
								<>
									<Route path='*' element={<NeedLogin />} />
									<Route path={'/view'} element={<ClientLogin />} />
									<Route path={'/view/:client'} element={<ClientLogin />} />
									<Route path={'/view/:client/:campaign'} element={<ClientLogin />} />
									<Route path={'/view/:client/:campaign/:key'} element={<ClientLogin />} />
									<Route path={'/view/:client/:campaign/:key/:creative'} element={<ClientLogin />} />
									<Route path={'/view/:client/:campaign/:key/:creative/:revision'} element={<ClientLogin />} />
								</>
							) : (
								<>
									{currentUser.company === 'stormx' ? (
										<>
											<Route path={'/admin/clients'} element={<ClientsList />} />
											<Route path={'/admin/users'} element={<AdminUsers />} />
											<Route path={'/view/:client/:campaign/users'} element={<Users />} />
											<Route path={'/view/:key/timeline'} element={<Timeline />} />
											<Route path={'/view/:client'} element={<AdminCampaignsPage />} />
										</>
									) : (
										<>
											<Route path={'/view'} element={<UserCampaignsPage />} />
										</>
									)}
									<Route path={'/view/:client/:campaign/:key/:creative/:revision'} element={<Gallery />} />
									<Route path={'/view/:client/:campaign/:key'} element={<Gallery />} />
									<Route
										path={'*'}
										element={currentUser?.company === 'stormx'
											? <Navigate to="/admin/clients" />
											: <Navigate to="/view" />
										}
									/>
								</>
							)}
						</Routes>
					</RecoilReactRouter>

					<Toaster
						toastOptions={{
							duration: 4000,
						}}
					/>
				</div>
				<Warning />
				<Tooltip id='my-tooltip' positionStrategy='fixed' style={{ zIndex: 9999 }} />
			</main>
		</QueryClientProvider>
	)
}
