import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useParams } from 'react-router-dom'

import { CampaignDetails, CreativesList, allTagsState, campaignDetailsSelector, currentCreative, currentUserAtom, filteredListSelector, router, selectedStatusState } from '../../AppState'

import CreativeGallery from './creative-gallery'
import FilterArea from './filter-area'
import ToggleGalleryLayout from './toggle-gallery-layout'
import Loading from '../../components/loading'
import CreativeList from './creative-list'

import { Campaign, Creative } from '../../types'

import useToggleLayout from '../../utils/useToggleLayout'
import LayoutContainer from '../../components/layout-container'
import Dropdown from '../../components/dropdown/dropdown'
import CampaignStatus from '../../components/campaign-status'
import toast from 'react-hot-toast'
import { Breadcrumb } from '@src/components/breadcrumb'

type CreativesOnCampaign = Campaign & {
    ok: boolean
    tags: string[]
    items: Creative[]
    size: number
    hasMore: boolean
}

export default function Gallery() {
    const { useGrid, isMobile } = useToggleLayout()
    const [loading, setLoading] = useState(true)
    
    const setListedTags = useSetRecoilState(allTagsState)
    const setCurrentCreative = useSetRecoilState(currentCreative)
    const [campaignDetails, setCampaignDetails] = useRecoilState(CampaignDetails)
    const [creativeList, setCreativeList] = useRecoilState(CreativesList)
    const setSelectedStatus = useSetRecoilState(selectedStatusState)
    const filteredList = useRecoilValue(filteredListSelector)
    const details = useRecoilValue(campaignDetailsSelector)
    const currentUser = useRecoilValue(currentUserAtom)
    const activeCreativeIndex = useRef<number | null>(null)

    const params = useParams()
    const client = params.client as string
    const campaign = params.campaign as string
    const creative = params.creative as string
    const firestore_key = params.key as string

    const loadCampaign = useCallback(async () => {
        try {
            setCreativeList([])
            setLoading(true)

            const url = `/api/common/campaigns/details/${firestore_key}`
            const request = await axios.get(url)

            const result = request.data as CreativesOnCampaign

            if (result.ok === true) {
                const { name, client, key, items, tags, url } = result

                setCreativeList(items)
                setCampaignDetails((prev) => ({ ...prev, name, client, key, url }))
                setListedTags([...tags])
            }

            setLoading(false)
        } catch (e: any) {
            console.error(e)

            if (e?.request?.status === 401) {
                toast.error('Sua sessão expirou, por favor faça login novamente.')
                return router.history.push('/logout')
            }
        }
    }, [])

    useEffect(() => {
        if (!useGrid) return;

        if (creativeList.length > 0 && creative) {
            const activeCreative = creativeList.find((c) => c.key === creative)

            if (activeCreative) {
                setCurrentCreative(activeCreative)
                activeCreativeIndex.current = filteredList.findIndex((c) => c.key === creative) || 0
            }
        } else {
            activeCreativeIndex.current = null
            setCurrentCreative(null)
        }
    }, [creativeList, filteredList, creative])

    useEffect(() => {
        loadCampaign()

        return () => {
            setCurrentCreative(null)
            setSelectedStatus(new Set(['approved', 'reproved', 'revised', 'new']))
        }
    }, [])

    return (
        <LayoutContainer>
            {currentUser?.company === 'stormx' && (
                <div className='w-full mb-4'>
                    <Breadcrumb pages={[
                        { name: 'Clientes', href: '/admin/clients', current: false },
                        { name: client, href: `/view/${client}`, current: false },
                    ]} />
                </div>
            )}
            {(useGrid || filteredList.length === 0) && (
                <>
                    <h2 className='text-md text-info font-semibold pl-2'>
                        Campanha: <span className='uppercase'>{campaignDetails.name}</span>
                    </h2>
                    <div className='flex w-full h-10 gap-x-3 items-start relative'>
                        <FilterArea />
                        <ToggleGalleryLayout isVisible={!isMobile} />
                    </div>
                </>
            )}

            <Loading isLoading={loading}>
                {useGrid ? (
                    <section className='flex flex-col relative flex-1 h-[calc(100%-110px)]'>
                        <div className='overflow-x-auto grow'>
                            <CreativeGallery creatives={filteredList} key={campaign} />
                        </div>
                        <div className='w-full absolute bottom-0 z-[10]'>
                            <Dropdown
                                title='Detalhes'
                                label={`Exibindo ${details.displaying} de ${details.total}.`}
                                children={<CampaignStatus />}
                            />
                        </div>
                    </section>
                ) : (
                    <section className='flex-1 w-full overflow-hidden'>
                        <CreativeList creatives={filteredList} />
                    </section>
                )}
            </Loading>
        </LayoutContainer>
    )
}
