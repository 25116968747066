import axios, { AxiosError } from 'axios'
import { saveAs } from 'file-saver'
import { router } from '../AppState'
import { Campaign, FormAddCampaign, FormEditCampaign, ResCampaignStatus } from '../types'
import { HttpService } from './infra/http.service'

export const onCreateCampaign = async (form: FormAddCampaign): Promise<ResCampaignStatus> => {
    try {
        const request = await axios.post('/api/admin/campaigns/add', form)
        const result = request.data as { ok: boolean; status: ResCampaignStatus }

        if (!result.ok) throw new Error('Error when add campaign.')
        return result.status
    } catch (error) {
        if (error instanceof Error || error instanceof AxiosError) {
            console.error('Error', error.message)
        }
        return Promise.reject('An unexpected error ocurred')
    }
}

export const onUpdateCampaign = async (campaignKey: string): Promise<ResCampaignStatus> => {
    try {
        const request = await axios.post('/api/admin/campaigns/update', { key: campaignKey })
        const result = request.data as { ok: boolean; status: ResCampaignStatus }

        if (!result.ok) throw new Error('Error when updated campaign.')
        return result.status
    } catch (error) {
        if (error instanceof Error) {
            console.error('Error: ', error.message)
        } else {
            console.error(error)
        }
        return Promise.reject('An unexpected error occurred.')
    }
}

export const onEditCampaign = async (campaignKey: string, data: FormEditCampaign) => {
    try {
        const response = await axios.put('/api/admin/campaigns/update-metadata', { id: campaignKey, data })
        return response.status
    } catch (error) {
        if (error instanceof Error) {
            console.error('Error: ', error.message)
        } else {
            console.error(error)
        }
        return Promise.reject('An unexpected error occurred.')
    }
}

type GetUserCampaignsResponse = {
    ok: boolean;
    campaigns: Campaign[];
};

export const onGetUserCampaignList = async (): Promise<Campaign[]> => {
    try {
        const request = await new HttpService().request<GetUserCampaignsResponse>({
            method: 'GET',
            url: `/api/common/user/campaigns`,
        })
        const result = request.data

        if (result.ok)
            return result.campaigns

        throw new Error('Error when searching for campaigns.')
    } catch (error: any) {
        console.error(error)

        return Promise.reject({
            message: error?.response?.message || 'An unexpected error occurred.',
            status: error?.response?.status || 500,
        })
    }
}

export const onDownloadReport = async (client: string, campaign: string) => {
    try {
        const result = await axios.get(`/api/admin/xls/client/${client}/campaign/${campaign}`, {
            responseType: 'blob',
        })
        saveAs(result.data, `${campaign}.xlsx`)
    } catch (error) {
        if (error instanceof Error) {
            console.error('Error: ', error.message)
        } else {
            console.error(error)
        }
        return Promise.reject('An unexpected error occurred.')
    }
}

export const goToCampaignDetails = () => { }

export const goToCampaign = (campaignUrl: string) => {
    router.history.push(campaignUrl)
}

export const goToActionsHistory = (campaignKey: string) => {
    router.history.push(`/view/${campaignKey}/timeline`)
}

export const goToUserList = (client: string, campaignKey: string) => {
    router.history.push(`/view/${client}/${campaignKey}/users`)
}

export const campaignOptions = {
    onCreateCampaign,
    onUpdateCampaign,
    onEditCampaign,
    onGetUserCampaignList,
    onDownloadReport,
    goToCampaignDetails,
    goToCampaign,
    goToActionsHistory,
    goToUserList,
}
