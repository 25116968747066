import React from "react";
import { Link } from "react-router-dom";

interface BreadCrumbProps {
  pages: {
    name: string,
    href: string,
    current: boolean
  }[]
}

export const Breadcrumb = ({
  pages
}: BreadCrumbProps) => {
  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol role="list" className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <Link
                to={page.href}
                aria-current={page.current ? 'page' : undefined}
                className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.name}
              </Link>
              {index < pages.length - 1 && (
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}