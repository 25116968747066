import React from 'react'
import TableHeaderCell from './table-header.cell'
import { currentUserAtom } from '@src/AppState'
import { useRecoilValue } from 'recoil'

const TableHeader = () => {
    const campaignAttr = ['name', 'updatedAt', 'createdAt']
    const currentUser = useRecoilValue(currentUserAtom)

    return (
        <thead>
            <tr>
                {campaignAttr.map((attr, idx) => (
                    <TableHeaderCell label={attr} className='bg-base-200' key={`${idx}-${attr}`} />
                ))}
                {currentUser?.company === 'stormx' && (
                    <th className='cursor-not-allowed bg-base-200'> Opções </th>
                )}
            </tr>
        </thead>
    )
}

export default TableHeader