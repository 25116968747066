import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'

import { currentUserAtom, router } from '../../AppState'
import { User } from '../../types'

import Loading from '../loading'

const logo = new URL('../../assets/logo.png', import.meta.url)

const Navbar = () => {
	const { logout } = useAuth0()
	const { location } = useRecoilValue(router.state)
	const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom)
	const [loading, setLoading] = useState(true)

	const onLogoffRequested = async () => {
		logout()
		await axios.post('/api/logoff')
		setCurrentUser(null)
	}

	const onLogoff = () => {
		onLogoffRequested()
	}

	const checkUser = async () => {
		setLoading(true)

		try {
			let req = await axios.get('/api/me')
			const me = req.data as null | User
			setCurrentUser(me)
		} catch (fault) {
			const error = fault as Error
			console.error('Authenticate Error ', error.message)
		} finally {
			setLoading(false)
		}
	}

	const isActive = useCallback((value: string) => (
		location.pathname.endsWith(value)
	), [location.pathname])

	useEffect(() => {
		if (currentUser === null && !loading) {
			checkUser()
		}
	}, [currentUser])

	return (
		<>
			<div className='navbar bg-base-400 z-20 p-0'>
				<div className='navbar-start lg:hidden'>
					{currentUser && (
						<div className='dropdown'>
							<label tabIndex={0} className='btn btn-ghost btn-circle'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M4 6h16M4 12h16M4 18h7'
									/>
								</svg>
							</label>
							<ul
								tabIndex={0}
								className='menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-200 rounded-box w-52 z-[99]'
							>
								{currentUser?.company === 'stormx' ? (
									<>
										<li tabIndex={0}>
											<a
												onClick={() => {
													router.history.push('/admin/clients')
												}}
											>
												Clientes
											</a>
										</li>
										<li tabIndex={0}>
											<a
												onClick={() => {
													router.history.push('/admin/users')
												}}
											>
												Usuários
											</a>
										</li>
									</>
								) : (
									<>
										<li tabIndex={0}>
											<a
												onClick={() => {
													router.history.push(`/view/${currentUser?.company}`)
												}}
											>
												Campanhas
											</a>
										</li>
									</>
								)}
								<li tabIndex={0}>
									<a onClick={onLogoffRequested}>Sair</a>
								</li>
							</ul>
						</div>
					)}
				</div>

				<div className='hidden lg:block navbar-start h-16'>
					<div className='flex items-center h-full'>
						<div className='flex h-full items-center px-4'>
							<img src={logo.toString()} className='h-5' />
						</div>

						<ul className='flex menu flex-row h-full p-0'>
							{currentUser?.company === 'stormx' ? (
								<>
									<li tabIndex={0} className='h-full'>
										<a
											className={classNames('h-full flex justify-center rounded-none', {
												'bg-slate-700/30': isActive('/admin/clients'),
											})}
											onClick={() => {
												router.history.push('/admin/clients')
											}}
										>
											Clientes
										</a>
									</li>
									<li tabIndex={0} className='h-full'>
										<a
											className={classNames('h-full flex justify-center rounded-none', {
												'bg-slate-700/30': isActive('/admin/users'),
											})}
											onClick={() => {
												router.history.push('/admin/users')
											}}
										>
											Adicionar usuários
										</a>
									</li>
								</>
							) : (
								<>
									<li tabIndex={0} className='h-full'>
										<a
											className={classNames('h-full flex justify-center rounded-none', {
												'bg-slate-700/30': isActive(`/view/${currentUser?.company}`),
											})}
											onClick={() => {
												router.history.push(`/view/${currentUser?.company}`)
											}}
										>
											Campanhas
										</a>
									</li>
								</>
							)}
						</ul>
					</div>
				</div>

				<div className='lg:hidden navbar-center'>
					<img src={logo.toString()} className='h-6' />
				</div>

				<div className='navbar-end'>
					{currentUser && (
						<div className='hidden lg:block'>
							<div className='dropdown dropdown-end dropdown-hover dropdown-bottom z-[99]'>
								<label tabIndex={0} className='btn btn-link'>
									{currentUser?.email}
								</label>
								<ul
									tabIndex={0}
									className='menu dropdown-content p-2 shadow bg-base-300 w-28 rounded-box rounded-t-none'
								>
									<li>
										<a onClick={onLogoff}>Sair</a>
									</li>
								</ul>
							</div>
						</div>
					)}
				</div>
			</div>

			{loading && <Loading text='Entrando' className='fixed z-50 left-0 top-0 bg-base-300' />}
		</>
	)
}

export default Navbar
